import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Sidebar from '../Comman/Sidebar'
import Staking from './Staking'
import { AdminContext } from '../../AdminContext'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import Team from './Team'
import StakingIncome from './StakingIncome'
import DirectReward from './DirectReward'
import CommunityReward from './PoolReward'
import RoyaltyClubReward from './RoyaltyClubReward'
import RankReward from './RankReward'
import Withdrawal from './Withdrawal'
import Ledger from './Ledger'
import PoolReward from './PoolReward'

export default function MemberReport() {
    const { address } = useParams();
    const { formatAddress, dbuser, formatDateTime, dbbalance } = useContext(AdminContext);
    const [data, setdata] = useState([]);
    const [totReward, setTotReward] = useState(0);
    const [balance, setbalance] = useState(0);
    const [level, setlevel] = useState(1);

    const getData = async () => {
        await axios
            .post(process.env.REACT_APP_BACKEND_LINK, {
                method: "user",
                submethod: "getbyid",
                key: process.env.REACT_APP_KEY,
                address,
            })
            .then((res) => {
                if (res.data.data) {
                    var dap = res.data.data;
                    var fbln =
                        dap.level_inc + dap.cashback_inc + dap.direct_inc + dap.magic_b_inc + dap.earth_p_inc + dap.global_p_inc + dap.rank_inc + dap.global_inc + dap.global_vip_inc + dap.w_royalty_inc + dap.n_w_royalty_inc - (dap.t_withdrawal + dap.pen_withdrawal + dap.d_m_entry + dap.d_e_entry + dap.d_g_entry);
                    setbalance(Number(fbln).toFixed(2));
                    console.log("member report", res.data.data);
                    setdata(res.data.data);
                }
                if (res.data.error) {
                    return "";
                }
            });
    };
    useEffect(() => {
        if (address) {
            getData();
        }
    }, [address]);
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    {/* Page sidebar start*/}
                    <Sidebar />
                    {/* Page sidebar end*/}
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>MemberReport</h3>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts*/}
                        <div className="container-fluid ecommerce-dashboard">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {/* Order Delivery*/}
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Staking Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.staking_rew : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Fox Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ 0</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Direct Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.direct_rew : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Community Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.level_rew : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Rank Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.rank_rew : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Royalty Club Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.r_club_rew : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Award and Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ 0</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Current Staking/Bot</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.c_staking : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Staking/Bot</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.t_staking : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Direct Team</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dbuser ? dbuser.d_team : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Team</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dbuser ? dbuser.team : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light"> Team Business</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.team_business : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Strong Leg</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.s_leg : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Other Leg</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.o_leg : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Required Strong Leg</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.ra_leg : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Required Other Leg</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.ro_leg : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Required Team</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dbuser ? dbuser.r_team : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Current Rank</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3"> {dbuser
                                                                ? dbuser.rank === null
                                                                    ? "No Rank"
                                                                    : dbuser.rank
                                                                : "-"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Next Rank</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dbuser ? dbuser.next_rank : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Withdrawal</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.t_withdrawal : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total SP</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.t_reward_staking : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Balance SP</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.staking_balance : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total MP</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.t_reward_mining : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Balance MP</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ {dbuser ? dbuser.mining_balance : 0}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Staking address={address} />
                            {/* <Team address={address} /> */}
                            <StakingIncome address={address} />
                            <DirectReward address={address} />
                            <PoolReward address={address} />
                            {/* <RoyaltyClubReward address={address} />
                            <RankReward address={address} /> */}
                            <Withdrawal address={address} />
                            {/* <Ledger address={address} /> */}
                        </div>
                        {/* Container-fluid ends*/}
                    </div>
                    <Footer />
                </div>

            </main>


        </>
    )
}
