import React, { useContext, useEffect, useState } from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Sidebar from '../Comman/Sidebar'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AdminContext } from '../../AdminContext';

export default function Dashboard() {
    const { settings, getSetting } = useContext(AdminContext);
    const [dashboardData, setDashboardData] = useState(null);
    const [dashboard, setDashboard] = useState(null);
    const statusChange = async () => {
        try {
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "setting",
                    submethod: "update",
                    key: process.env.REACT_APP_KEY,
                    status: !settings.staking,
                })
                .then((res) => {
                    if (res.data.error) {
                        return "";
                    }
                    getSetting();
                });
        } catch (err) {
            console.log("error : ", err);
        }
    };
    const getDashboardData = async () => {
        try {
            await axios
                .post(process.env.REACT_APP_BACKEND_LINK, {
                    method: "user",
                    submethod: "admindashd",
                    key: process.env.REACT_APP_KEY,
                })
                .then((res) => {
                    if (res.data.error) {
                        return "";
                    }
                    // console.log("user dashdata 123 12142154", res.data.data);
                    // console.log("user dashdata 123", res.data.today);
                    setDashboardData(res.data.data);
                    setDashboard(res.data.today);
                });
        } catch (err) {
            console.log("error : ", err);
        }
    };
    useEffect(() => {
        getDashboardData();
        // getSettingData();
    }, []);
    return (
        <>
            <main className="page-wrapper compact-wrapper" id="pageWrapper">
                <Header />
                <div className="page-body-wrapper">
                    {/* Page sidebar start*/}
                    <Sidebar />
                    {/* Page sidebar end*/}
                    <div className="page-body">
                        <div className="container-fluid">
                            <div className="row page-title">
                                <div className="col-sm-6">
                                    <h3>NETWORK1 Dashboard</h3>
                                </div>
                                <div className="col-sm-6">
                                    <div className='d-flex justify-content-end'>
                                        <Link to="/deposit" className="btn btn-primary mx-2">Pending Transcation</Link>
                                        {/* <button className="btn btn-primary mx-2" disabled>Security Vault</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid starts*/}
                        <div className="container-fluid ecommerce-dashboard">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        {/* Order Delivery*/}
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Node Users</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.t_user : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Active Node Users</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.active_user : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Node Staking Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.totalStakingReward : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Community Reward</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.level_rew : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Node Staking</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.t_staking : "0"}{" "}{process.env.REACT_APP_TICKER_USDN}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Node Sold</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.t_node : "0"}{" "}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Node Staking</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.t_staking_token : "0"}{" "}{process.env.REACT_APP_TICKER}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Unstake</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.t_unstake : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">3x Users</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.income_capability : "0"}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Node Withdrawal</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.t_withdrawal : "0"}{" "}{process.env.REACT_APP_TICKER}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Total Node Withdrawal Coin</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.t_withdrawal_coin : "0"}{" "}{process.env.REACT_APP_TICKER_USDN}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Node Pending Withdrawal</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.p_withdral : "0"}{" "}{process.env.REACT_APP_TICKER}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Node Pending Withdrawal Coin</h5>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">{dashboardData ? dashboardData.p_withdrawal_coin : "0"}{" "}{process.env.REACT_APP_TICKER_USDN}</h3>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Change Staking Status</h5>
                                                            </div>
                                                            <button className={`btn btn-${settings !== null ? settings.staking ? "success" : "danger" : 'primary'} mx-2`} onClick={() => statusChange()}>{settings !== null ? settings.staking ? "Current Active" : "Current Deactive" : ''}</button>
                                                            <br />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">New Orders</h5>
                                                                <div className="badge align-items-center d-flex badge-light-success">
                                                                    <svg className="feather me-1 pt-0 stroke-success">
                                                                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-down-right" />
                                                                    </svg><span className="f-w-500">4.5%</span>
                                                                </div>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ 51,325</h3>
                                                        </div>
                                                        <div className="flex-shrink-0 bg-light-secondary">
                                                            <svg className="svg-w-30 fill-secondary">
                                                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#order-product" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">
                                                    <div>
                                                        <p className="f-w-500">14% since Last Month</p>
                                                    </div>
                                                    <div className="order-chart">
                                                        <div className="chart" id="order-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Order Delivery</h5>
                                                                <div className="badge align-items-center d-flex badge-light-danger">
                                                                    <svg className="feather me-1 pt-0 stroke-danger">
                                                                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-up-right" />
                                                                    </svg><span className="f-w-500">2.3%</span>
                                                                </div>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ 32,587</h3>
                                                        </div>
                                                        <div className="flex-shrink-0 bg-light-tertiary">
                                                            <svg className="svg-w-31 fill-tertiary">
                                                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#delivery-van" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">
                                                    <div>
                                                        <p className="f-w-500">10% since Last Month</p>
                                                    </div>
                                                    <div className="order-chart">
                                                        <div className="chart" id="delivery-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="card progress-items">
                                                <div className="card-header pb-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex gap-2">
                                                                <h5 className="f-18 font-light">Order Delivery</h5>
                                                                <div className="badge align-items-center d-flex badge-light-danger">
                                                                    <svg className="feather me-1 pt-0 stroke-danger">
                                                                        <use href="https://admin.pixelstrap.net/edmin/assets/svg/feather-icons/dist/feather-sprite.svg#arrow-up-right" />
                                                                    </svg><span className="f-w-500">2.3%</span>
                                                                </div>
                                                            </div>
                                                            <h3 className="f-26 f-w-600 mt-3">$ 32,587</h3>
                                                        </div>
                                                        <div className="flex-shrink-0 bg-light-tertiary">
                                                            <svg className="svg-w-31 fill-tertiary">
                                                                <use href="https://admin.pixelstrap.net/edmin/assets/svg/iconly-sprite.svg#delivery-van" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body progress-card pt-0">
                                                    <div>
                                                        <p className="f-w-500">10% since Last Month</p>
                                                    </div>
                                                    <div className="order-chart">
                                                        <div className="chart" id="delivery-chart" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Container-fluid ends*/}
                    </div>
                    <Footer />
                </div>

            </main>


        </>
    )
}
